import React from "react";
import { useNavigate } from "react-router-dom";
import "../comp-styles/notificaciones.scss";
import Iconos from "../IconosDeSitio";
import { Row } from "antd";
import { getAuthFirebase_request } from "../../services/notificaciones_services";

export default function NotificacionesCard({ notificaciones, redireccion }) {

  async function readNotification(id) {
    await getAuthFirebase_request({ accion: 0, idNotification: id });
  }

  return (
    <>
      {notificaciones.map((notificacion) => (
        <div className={notificacion.status === 0 ? "ContenidoNotiCardNoRead" : "ContenidoNotiCardRead"} key={notificacion.id}
          onClick={() => {
            // PRO NUEVO
            if (notificacion.status === 0) {
              readNotification(notificacion.id);
            }
            redireccion(notificacion.module, notificacion)
          }}>
          <img
            className="imgNotiCard"
            src={notificacion.userImg !== undefined && !!notificacion.userImg
              ? notificacion.userImg
              :
              Iconos[0]
            }
            alt=""
          />
          <div style={{ width: "100%" }}>
            <Row style={{ justifyContent: "space-between" }}>
              <b>
                {notificacion.userName}
              </b>
              <b>
                {/* {new Date(notificacion.createdTimeUtc).toLocaleDateString('es-ES', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })} */}

                {notificacion.fecha}
              </b>
            </Row>
            <p>
              {notificacion.values}
            </p>
          </div>
        </div>
      ))}
    </>
  );
}
