import React from "react";
// import BigCalendar from "react-big-calendar";
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import "dayjs/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = dayjsLocalizer(dayjs)

export default function CalendarPage({ clickCallback, citas }) {
    const components = {
        event: props => {
            console.log(props)
            return (
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>{props.event.icon}<p style={{marginLeft:5}}>{props.title}</p></div>
            )
        }
    }
    return (
        <div>
            <Calendar
                localizer={localizer}
                events={citas}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={(e) => {
                    clickCallback(e.id, 1);
                }}
                style={{ height: "500px" }}
                culture="es"
                messages={{
                    next: "siguiente",
                    previous: "anterior",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                    date:"Fecha",
                    time:"Hora",
                    event:"Evento"
                }}
                components={components}
                eventPropGetter={
                    (event, start, end, isSelected) => {
                      let newStyle = {
                        backgroundColor: "#00BCD4",
                        color: 'black',
                        borderRadius: "0px",
                        border: "none",
                        borderRadius: 7
                      };
                
                      return {
                        className: "",
                        style: newStyle
                      };
                    }
                  }
            />
        </div>
    );

}
